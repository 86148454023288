export const alumni = [
  {
    "name": "Christine Baker",
    "img": "christine_baker.jpg",
    "company": "JP Morgan"
  },
  {
    "name": "Morganne Farinha",
    "img": "morganne_farinha.jpg",
    "company": "Oracle"
  },
  {
    "name": "Seanie Clark",
    "img": "seanie_clark.jpg",
    "company": "Citi"
  },
  {
    "name": "Stephanie Browder",
    "img": "stephanie_browder.jpg",
    "company": "UBS"
  },
  {
    "name": "Camden Wiseman",
    "img": "camden_wiseman.jpg",
    "company": "RBC"
  },
  {
    "name": "Devansh Lahar",
    "img": "devansh_lahar.jpg",
    "company": "Goldman Sachs"
  },
  {
    "name": "Sarah Ashkar",
    "img": "sarah_ashkar.jpg",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Caroline Paul",
    "img": "caroline_paul.jpg",
    "company": "UBS"
  },
  {
    "name": "Andrea Candidato",
    "img": "andrea_candidato.jpg",
    "company": "Evercore"
  },
  {
    "name": "Alexander Steigerwald",
    "img": "alexander_steigerwald.jpg",
    "company": "UBS"
  },
  {
    "name": "Bella Cruz",
    "img": "bella_cruz.jpg",
    "company": "Morgan Stanley"
  },
  {
    "name": "Ananya Gupta",
    "img": "ananya_gupta.jpg",
    "company": "PwC"
  },
  {
    "name": "Winston Feng",
    "img": "winston_feng.png",
    "company": "Goldman Sachs, Point72, MassAve"
  },
  {
    "name": "Samantha Kaner",
    "img": "samantha_kaner.png",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Hrishi Bopalkar",
    "img": "hrishi_bopalkar.png",
    "company": "Greenhill & Co. Restructuring"
  },
  {
    "name": "Stratton Papakirk",
    "img": "stratton_papakirk.png",
    "company": "Blackstone"
  },
  {
    "name": "Alex Alma",
    "img": "alex_alma.jpg",
    "company": "Centerview Partners"
  },
  {
    "name": "Sadie Ravnitzky",
    "img": "sadie_ravnitzky.png",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Nick Hahn",
    "img": "nick_hahn.jpg",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Dhanush Kota",
    "img": "dhanush_kota.jpg",
    "company": "BofA, Sumeru Equity Partners"
  },
  {
    "name": "Rohan Gharekhan",
    "img": "rohan_garekhan.jpg",
    "company": "Goldman Sachs"
  },
  {
    "name": "Reed Hammer",
    "img": "reed_hammer.jpg",
    "company": "Goldman Sachs"
  },
  {
    "name": "Oliver Fisher",
    "img": "oliver_fisher.jpg",
    "company": "Goldman Sachs"
  },
  {
    "name": "Siyana Popova",
    "img": "siyana_popova.jpg",
    "company": "Morgan Stanley"
  },
  {
    "name": "Charlie Russell",
    "img": "charlie_russell.png",
    "company": "JP Morgan"
  },
  {
    "name": "Bradley Schoenfeld",
    "img": "bradley_schonfeld.jpg",
    "company": "Guggenheim Partners"
  },
  {
    "name": "Rachel Marsh",
    "img": "rachel_marsh.jpg",
    "company": "JP Morgan"
  },
  {
    "name": "Dhaval Patel",
    "img": "dhaval_patel.jpg",
    "company": "BlueCrest Capital"
  },
  {
    "name": "Bhoomika Jain",
    "img": "bhoomika_jain.png",
    "company": "BofA, Sumeru Equity Partners"
  },
  {
    "name": "Yoav Grainer",
    "img": "yoav_grainer.png",
    "company": "Barclays"
  },
  {
    "name": "Jonathan Glinton",
    "img": "jonathan_glinton.jpg",
    "company": "Bank of America"
  },
  {
    "name": "Matias Zorrilla",
    "img": "matias_zorrilla.jpg",
    "company": "Bank of America"
  },
  {
    "name": "Evan Byers",
    "img": "evan_byers.jpg",
    "company": "Houlihan Lokey"
  },
  {
    "name": "Darius Tam",
    "img": "darius_tam.jpg",
    "company": "Raymond James Restructuring"
  },
  {
    "name": "Matthew Tan",
    "img": "matthew_tan.png",
    "company": "EY-Parthenon"
  },
  {
    "name": "Ethan Rozin",
    "img": "ethan_rozin.jpg",
    "company": "Truist"
  },
  {
    "name": "Connor Novak",
    "img": "connor_novak.jpg",
    "company": "Barclays"
  },
  {
    "name": "Nicole Warszawski",
    "img": "nicole_warszawski.jpg",
    "company": "Wells Fargo"
  }
]

export const members = {
  "leadership": [
    {
      "name": "Travis Thai",
      "position": "President",
      "desc": "Travis Thai is a senior from Princeton, New Jersey studying Economics and Government at the College of Arts and Sciences. On campus, Travis serves on the Executive Board of Cornell Alpha Fund; he is also a member of Cornell Financial Analysts, Diversity on the Street, the Cornell Intellectual Property and Ethics Club, and the University Hearing and Review Panel. This past summer, Travis worked as an Investment Banking Summer Analyst at J.P. Morgan in the Media and Communications group, where he will be returning full time. Travis has previously interned at Guardian Life, Cornell's Emerging Markets Institute, and a venture capital firm. In his spare time, Travis enjoys exploring new restaurants, watching thriller films, and learning more about contemporary history. Travis can be reached at tjt58@cornell.edu.",
      "pic": "travis_thai.jpg"
    },
    {
      "name": "Andrew Lenk",
      "position": "Executive Vice President",
      "desc": "Andrew Lenk is a senior from Hong Kong pursuing a major in Human Biology, Health, and Society in the College of Human Ecology with a minor in Business. On campus, Andrew serves as the VP of New Member Education for Social Enterprise Group at Cornell (SEGC) and as a member of the Business team in the CUBMD Project Team. This past summer, Andrew interned as a Corporate Banking Summer Analyst at CIBC. In his free time, Andrew enjoys playing chess, learning about history, cooking new dishes, and film. He can be reached at arl249@cornell.edu.",
      "pic": "andrew_lenk.jpg"
    },
    {
      "name": "Pinak Chitnis",
      "position": "VP of Investing Education",
      "desc": "Pinak Chitnis is a junior from Cleveland, OH majoring in Applied Economics and Management with a concentration in Finance. On campus, he is also involved in Cornell Financial Analysts. This past summer, he worked at Sora Investors as an investment analyst in long/short equity. Next summer, Pinak will be working at Citadel as an investment analyst in long/short equity. In his free time, he enjoys hanging out with friends, watching sports, and trying new restaurants. He can be reached at puc4@cornell.edu.",
      "pic": "pinak_c.jpg"
    },
    {
      "name": "Pranav Dendukuri",
      "position": "VP of Investing Education",
      "desc": "Pranav Dendukuri is a junior from Long Island, NY, majoring in Applied Economics and Management with a concentration in Finance. On campus, he is also a part of Cornell Financial Analysts, Cornell Undergraduate Asia Business Society, and Cornell Alternative Investments. This past summer he worked at a hedge fund, focusing on consumer healthcare and has previous experiences as an investment banking intern at Freyeur & Trogue. Next summer, Pranav will be joining Point72 as an  Summer Academy Analyst. Outside of school, Pranav is an avid movie watcher, enjoys playing sports with his friends, and loves TikTok. He can be reached at pvd6@cornell.edu.",
      "pic": "pranav_d.jpg"
    },
    {
      "name": "Patrick Fang",
      "position": "VP of Investment Fund",
      "desc": "Patrick Fang is a sophomore from Westchester, NY, majoring in Applied Economics and Management with an intended concentration in Finance and a minor in data science. On campus, Patrick is also a member of Cornell Financial Analysts. This past summer, Patrick worked as an investment analyst at a small-cap hedge fund. In his free time, Patrick enjoys spending time with friends, playing volleyball and basketball, listening to music, and street photography. Patrick can be reached at pbf37@cornell.edu.",
      "pic": "patrick_fang.jpg"
    },
    {
      "name": "Jason Orenstein",
      "position": "VP of Internal Affairs & Finance",
      "desc": "Jason Orenstein is a sophomore from Albany, New York studying Hotel Administration with a concentration in finance and a minor in Real Estate. On campus, he is also a part of Cornell GameChangers, HEC, and CREC. This past summer, he worked as an intern at Trustage and First New York Federal Credit Union in private wealth management and financial planning. Jason is passionate about sports and enjoys eating around Ithaca. He can be reached at jmo235@cornell.edu or 518-709-3942.",
      "pic": "jason_orenstein.jpg"
    },
    {
      "name": "Sahil Voona",
      "position": "VP of Internal Affairs & Finance",
      "desc": "Sahil Voona is a Sophomore from New Jersey majoring in Applied Economics and Management. On campus, he is the Fintech Sector lead for Diversity on the Street and is actively involved with Cornell Investment Banking Club, South Asian Business Association, and Cornell Fintech Club. This past summer, he worked as a Summer Analyst at Cornell’s Investment Office. Sahil is passionate about community service, enjoys playing basketball and soccer, and loves watching movies and his favorite football team, the New York Giants.",
      "pic": "sahil_voona.jpg"
    },
    {
      "name": "Angelina Gart",
      "position": "VP of Recruitment",
      "desc": "Angelina Gart is a junior from New York City majoring in Applied Economics and Management with a concentration in Finance and a minor in Spanish. On campus, she is President of MergerSight Group and co-teaches their M&A Education Program. She is also a member of Cornell Financial Analysts. This past summer, she worked at Oak Hill Capital as a Private Equity Summer Analyst on their Industrials team and completed the Girls Who Invest Summer Intensive Program. Next summer, Angelina will be working at J.P. Morgan as an Investment Banking Summer Analyst. Angelina is passionate about languages and enjoys fine dining at new places. She can be reached at ag995@cornell.edu.",
      "pic": "angelina_gart.jpg"
    },
    {
      "name": "Matthew Devonish",
      "position": "VP of Recruitment",
      "desc": "Matthew Devonish is a sophomore from Brooklyn, New York, majoring in Applied Economics and Management with an intended concentration in finance. On campus, he is also a member of Cornell Financial Analysts. This past summer, Matthew worked as a private equity intern at Strategic Value Partners, focusing on distressed investment opportunities. In his free time, Matthew enjoys working out, following the NBA, and playing poker. He can be reached at med328@cornell.edu.",
      "pic": "matt_devonish.jpg"
    },
    {
      "name": "Alex Noviello",
      "position": "VP of Technology",
      "desc": "Alex Noviello is a sophomore in the College of Arts & Sciences, studying computer science and math. On campus, Alex is involved with a variety of entrepreneurship-related activities, including working on his own startup Subito. This past summer, Alex was primarily working on his startups supported by the Life Changing Labs Accelerator, NYC-based Keel accelerator, the Cornell Beck Fellowship, the Emergent Ventures Fellowship, and the 1517 Fund Medici grant. Alex also helped lead tech for a Cornell-alum founded procurement optimization startup. Alex enjoys playing golf poorly, watching good golf, scuba diving, skiing, and wildlife! Alex can be reached at abn52@cornell.edu.",
      "pic": "alex_noviello.jpg"
    },
    {
      "name": "Andrew Noviello",
      "position": "VP of Technology",
      "desc": "Andrew Noviello is a sophomore from Bridgewater, New Jersey majoring in Computer Science and Mathematics. On campus, he is a member of various Cornell entrepreneurship groups and the Cornell M&A club. This past summer, Andrew interned in software development at Samsung. He is currently working on startup company Subito, building frontier GenAI models for use cases in music education. With Subito, this past summer he participated in Keel Ventures Cohort 1.0 and the Life Changing Labs Accelerator, while earning funding from the 1517 Fund, the Beck Fellowship, and the Emergent Ventures Fellowship for research and development. Alongside finance and technology, Andrew is an accomplished cellist and passionate about travel and environmental conservation. He can be reached at acn57@cornell.edu.",
      "pic": "andrew_noviello.jpg"
    },
    {
      "name": "Elsa Li",
      "position": "Alumni Chair",
      "desc": "Elsa Li is a sophomore from Northern Virginia majoring in Hotel Administration. On campus, she is involved with Cornell Financial Analysts, Cornell Undergraduate Asia Business Society, Cornell Real Estate Club, and Shadows Dance Troupe. This past summer, she worked as a Private Equity Summer Analyst at ADV Partners and a Capital Markets & Investments Summer Intern at Colliers in Shanghai. Next summer, she will be working as a Real Estate Investment Banking Summer Analyst at Morgan Stanley. In her free time, Elsa enjoys dancing, watching soccer and hockey, eating acai bowls, and exploring new cities. Elsa can be reached at eml245@cornell.edu.",
      "pic": "elsa_li.png"
    },
    {
      "name": "Ariane Chevrier",
      "position": "DEI Chair",
      "desc": "Ariane Chevrier is a sophomore from New York, NY, majoring in Applied Economics and Management within the S.C. Johnson College of Business. On campus, she is Vice President of Finance of Cornell Financial Analysts and a teaching assistant for AEM 2200 Business Management & Organization. This past summer, Ariane worked as a Business and Operations Analyst at Le Printemps, one of France’s most storied luxury department store chains. In her free time, Ariane enjoys watching Formula 1, binging TV shows, and playing soccer. Ariane can be reached at afc74@cornell.edu.",
      "pic": "ariane_chevrier.jpg"
    },
    {
      "name": "Sadie Burke",
      "position": "Social Chair",
      "desc": "Sadie Burke is a sophomore from Denver, Colorado majoring in Hotel Administration. On campus, she is the Recruitment Chair of Cornell Alternative Investments. She is also a member of Cornell Financial Analysts and TAMID Group. This past summer, she worked as a Private Equity Summer Analyst at Copley Equity Partners. Next summer, she will be working at Morgan Stanley as an Investment Banking Summer Analyst. Sadie loves trying new restaurants and watching Suits. She can be reached at srb339@cornell.edu.",
      "pic": "sadie_burke.jpg"
    },
    {
      "name": "Kurumi Shimura",
      "desc": "Kurumi Shimura is a senior from New York City majoring in Applied Economics and Management with a concentration in finance. On campus, she is involved with Cornell Financial Analysts, Cornell Alternative Investments, and Dyson Career Peer Advising, and she serves as a course assistant for AEM 2200: Business Organization and Management. This past summer, Kurumi interned as a generalist M&A investment banking summer analyst at Centerview Partners, where she will be returning full time. Previously, she interned at Bank of America’s investment banking division in the Financial Sponsors Group. In her free time, she enjoys watching tennis and Formula 1, completing word puzzles, and traveling. Kurumi can be reached at kts46@cornell.edu.",
      "pic": "kurumi_shimura.jpg",
      "position": "Senior Advisor"
    },
    {
      "name": "David Feng",
      "desc": "David Feng is a senior from Orange County, California majoring in AEM with a minor in History. David is the Co-President Emeritus of Alpha Fund and can be reached at df443@cornell.edu.",
      "pic": "david_feng.jpg",
      "position": "Senior Advisor"
    },
  ],
  "members": [
    {
      "name": "Chloe Miller",
      "desc": "Chloe is a junior from Buffalo, NY studying Applied Economics and Management. Outside of Alpha Fund, she is involved in the Cornell Investment Banking Club, Dyson Undergraduate Council, Dyson Ambassadors, and a social sorority. Next summer, she will be working as an Investment Banking Summer Analyst for UBS. In her free time, she likes going on walks and watching the Buffalo Bills.",
      "pic": "chloe_miller.jpg",
      "grade": "Senior"
    },
    {
      "name": "Ari Salsberg",
      "desc": "Ari is a senior from New York City majoring in Applied Economics and Management within the S.C. Johnson College of Business. He formerly served as the Co-Director of Recruitment and Vice President of Engagement for Alpha Fund. Currently, Ari serves as the President of TAMID at Cornell and the Senior Portfolio Manager of TAMID’s global portfolio. He also is the Executive Vice President of Cornell Financial Analysts (CFA). Ari previously interned as an Investment Banking Summer Analyst at Evercore in the Tel Aviv office. This summer, he worked as an Investment Banking Summer Analyst at Perella Weinberg Partners where he will be returning full time upon graduation. In his free time, he enjoys cooking and spending Sundays watching NFL football. He can be reached at ajs556@cornell.edu.",
      "pic": "ari_salsberg.jpg",
      "grade": "Senior"
    },
    {
      "name": "Grace Michalowski",
      "desc": "Grace Michalowski is a senior from Connecticut majoring in Applied Economics and Management with concentrations in Finance and Entrepreneurship and a minor in Real Estate. On campus, she is a captain of the Women's Rowing Team. This past summer, she worked at Barclays as an Investment Banking Summer Analyst on their M&A team. She will be returning full time next year. Grace loves to run, try new restaurants, and eat froyo (Jason's). She can be reached at gcm57@cornell.edu.",
      "pic": "grace_m.jpg",
      "grade": "Senior"
    },
    {
      "name": "Noa Diener",
      "desc": "Noa Diener is a senior from Miami Beach, Florida majoring in Applied Economics and Management and minoring in Real Estate. On campus, she is involved in Cornell Alternative Investments and Cayuga Capital. She is also a Teaching Assistant for Finance for Dyson Majors and Real Estate Financial Modeling. This past summer, she worked at PIMCO as a Private Commercial Real Estate Summer Analyst. Noa is an equestrian and regularly competes in nationally and internationally recognized events. She can be reached at ndd26@cornell.edu.",
      "pic": "noa_diener.jpg",
      "grade": "Senior"
    },
    {
      "name": "Elodie Wang",
      "desc": "Elodie Wang is a senior from Paris studying Applied Economics and Management, with a concentration in Finance and Strategy. Last summer, she started her own phone accessories and jewelry company. In her free time, Elodie enjoys hanging out with friends and trying out new restaurants.",
      "pic": "elodie_wang.jpg",
      "grade": "Senior"
    },
    {
      "name": "Evan Nicholas",
      "desc": "Evan Nicholas is a senior from Demarest, New Jersey studying Hotel Administration, seeking a concentration in Finance. This past summer, he worked as an Investment Banking Summer Analyst at Morgan Stanley. Evan plans on returning as a full time analyst after graduation. He is currently on the Varsity Sprint Football Team and enjoys hiking and videography.",
      "pic": "evan_nicholas.jpg",
      "grade": "Senior"
    },
    {
      "name": "Payton Treaster",
      "desc": "Payton Treaster is a senior from State College, PA majoring in Hotel Administration with a concentration in finance and real estate. On campus, she is involved in Cayuga Capital as well as Alpha Fund. This past summer, she worked at Madison Industries in the medical vertical as a Finance Intern. She can be reached at pat73@cornell.edu.",
      "pic": "payton_treaster.jpg",
      "grade": "Senior"
    },
    {
      "name": "Eric Liang",
      "desc": "Eric Liang is senior from Long Island, NY majoring in Applied Economics and Management with a concentration in Finance and a minor in Real Estate. On campus, he is involved in the Cornell Chamber Orchestra and consulting clubs on campus. This past summer, he worked at Focus Financial Partners as an M&A Summer Analyst and is returning full-time. Eric can be found spending as much time outdoors whether at the beach, mountains, restaurants and more. He can be reached at eyl29@cornell.edu.",
      "pic": "eric_liang.jpg",
      "grade": "Senior"
    },
    {
      "name": "Yudai Higuchi",
      "desc": "Yudai Higuchi is a senior from Yokohama, Japan, majoring in Hotel Administration with a minor in Real Estate. On campus, he is Co-President of the Cornell Real Estate Club, and is also involved in Cornell Alpha Fund and the Wardrobe. This past summer, he interned at Blackstone in the Real Estate Acquisitions team. Previously, he interned at TPG in the real estate team. In his free time, he enjoys playing music, rowing, and cooking. Yudai can be reached at yh459@cornell.edu.",
      "grade": "Senior",
      "pic": "yudai_higuchi.jpg"
    },
    {
      "name": "Cameron Meyer",
      "desc": "Cameron Meyer is a junior from Alamo, CA double majoring in economics and biology & society. Previously, Cameron worked as an investment banking summer analyst for Morgan Stanley. In his free time, Cameron enjoys golfing and playing tennis.",
      "grade": "Junior",
      "pic": "cameron_meyer.jpg"
    },
    {
      "name": "Kevin Huang",
      "desc": "Kevin is a Junior from Long Island, NY in Dyson, concentrating in Finance, and minoring in Math and Data Science. On campus, he is also involved in Cornell Alpha Fund, Phi Gamma Nu, and Johnson Private Equity Club. This summer, he worked at Crestview Partners in NYC as a Private Equity Summer Analyst. Next summer, he will be joining Morgan Stanley as an Investment Banking Summer Analyst. Kevin is passionate about education equity, and enjoys competitive powerlifting, traveling, basketball, and picking up new hobbies. Kevin can be reached at kh639@cornell.edu.",
      "pic": "kevin_huang.jpg",
      "grade": "Junior"
    },
    {
      "name": "Luca Kalischer-Stork",
      "desc": "Luca Kalischer-Stork is a junior from Los Angeles majoring in Applied Economics and Management with a concentration in Finance. On campus, he is the executive vice president of banking at Cornell and a member of Cornell Financial Analysts. This past summer, he worked at a startup focused on adapting the factoring industry to blockchain. Luca is passionate about MMA, traveling, film and enjoys exploring new restaurants. He can be reached at lak257@cornell.edu.",
      "grade": "Junior",
      "pic": "luca_stork.jpg"
    },
    {
      "name": "Joshua Dong",
      "desc": "Joshua Dong is a junior from Long Island, NY, in Dyson concentrating in Finance with minors in Real Estate and Fungal Biology. On campus, Josh is also an associate at CREC Consulting and is involved with the real estate mentorship program. Last summer, Josh interned at Skybridge Capital pitching alternative investment ideas and analyzing hedge funds strategies. In his free time, Josh enjoys vlogging, non-profit work, poker, and reading.",
      "pic": "josh_dong.jpg",
      "grade": "Junior"
    },
    {
      "name": "Jake Friedman",
      "desc": "Jake is a junior from Westchester, NY majoring in Industrial and Labor Relations with a minor in Sustainable Business and Economic Policy. On campus he is a part of the Mutual Investment Club, MergerSight, and Cornell Technology Consulting. This past summer, he worked in New York Life’s field productivity division. He is also an incoming Investment Banking Summer Analyst for Mizuho. In his free time he enjoys skiing, listening to music, and watching the New York Jets. Jake can be reached at jlf342@cornell.edu.",
      "grade": "Junior",
      "pic": "jake_friedman.jpg"
    },
    {
      "name": "Georgia Kelly",
      "desc": "Georgia Kelly is a Junior from Dublin, Ireland majoring in Economics. On campus, she is a varsity athlete on the Field Hockey team and is a member of Cornell Financial Analysts. Last summer she worked in Morgan Stanley's investment banking division where she will be returning next summer. She enjoys travelling and spending time with friends. She can be reached at gjk72@cornell.edu.",
      "pic": "georgia_kelly.jpg",
      "grade": "Junior"
    },
    {
      "name": "Lakshman Sibal",
      "desc": "Lakshman is a sophomore from Edgemont, New York majoring in Industrial & Labor Relations with minors in Biometry & Statistics and Business. On campus, he is a Research Assistant for an AI-based asset pricing and portfolio optimization project in the Digital Economy & Financial Technology Lab in the SC Johnson School of Business. He is also part of the Cornell Emerging Markets Society. This past summer, he worked at Briarcliffe Credit Partners as a Private Credit Summer Analyst. Lakshman enjoys watching sports and exercising in his free time. He can be reached at lhs87@cornell.edu.",
      "pic": "lakshman_sibal.jpg",
      "grade": "Junior"
    },
    {
      "name": "William Najarian",
      "desc": "William Najarian is a junior from New York, NY, majoring in Applied Economics and Management. On campus, he is in the Cornell Investment Banking Club as well as the Phi Gamma Nu Business Fraternity. Last summer, he interned as an analyst at a Venture Capital firm. In his free time, William enjoys watching the Yankees, playing tennis, skiing, and watching F1 racing. He can be reached at wjn25@cornell.edu.",
      "pic": "william_najarian.jpg",
      "grade": "Junior"
    },
    {
      "name": "Otto Tiemann",
      "desc": "Otto Tiemann is a junior from Frankfurt, Germany majoring in Applied Economics & Management and Environment & Sustainability. On Campus he is involved with the Cornell Rugby Football Club, International Students Union and Cornell Horological Society. This past he interned in Real Estate Private Equity and will be working at Nomura next summer as an Investment Banking Summer Analyst. Otto is passionate about athletics and Alpha Fund.",
      "pic": "otto_tiemann.jpg",
      "grade": "Junior"
    },
    {
      "name": "Adam Dickstein",
      "desc": "Adam is a junior from New York City majoring in Economics within the College of Arts and Sciences. On campus, Adam is apart of the Cornell Investment Banking Club (CIBC) and the TAMID Group. This past summer, he interned at SkyBridge Capital, an alternative asset manager. Next summer, Adam will be working at Oppenheimer & Co. as an Investment Banking Summer Analyst. In his free time, Adam enjoys playing sports and hanging out with friends. He can be reached at asd237@cornell.edu.",
      "pic": "adam_dickstein.jpg",
      "grade": "Junior"
    },
    {
      "name": "Arin Atluri",
      "desc": "Arin is a junior from Westchester, NY majoring in Applied Economics and Management with concentrations in Finance and Business Analytics. On campus, he is also a member of the Cornell Club Tennis team and a teaching assistant for AEM 2600. Previously, he has worked as a corporate finance intern at L'Oréal USA, and as a venture capital intern for a MedTech startup called IrisMed. Outside of school, Arin loves watching the ATP Tour and the NBA, and also enjoys attending Auto Shows. Arin can be reached at aa2376@cornell.edu.",
      "pic": "arin_atluri.jpg",
      "grade": "Junior"
    },
    {
      "name": "Alex Siegel",
      "desc": "Alex Siegel is a sophomore from Long Island, NY, majoring in Public Policy with minors in Data Science and Business. On campus, he is also involved with Cornell Financial Analysts and Men's Club Lacrosse. This past summer, he interned for an equity long/short multi-manager hedge fund. In his free time, Alex enjoys golfing, playing poker, and watching the NFL. Alex can be reached at acs338@cornell.edu.",
      "pic": "alex_siegel.jpg",
      "grade": "Sophomore"
    },
    {
      "name": "Nicolas Thollot",
      "desc": "Nicolas is a sophomore from New York City studying Applied Economics and Management, with an intended concentration in Business Analytics and minor in Data Science. He is currently working for a family office as an Investment Researcher and Analyst and is also leading a rising student organization focused on quantitative trading. In his free time, Nicolas enjoys playing soccer, golfing, and hanging out with friends.",
      "pic": "nico_thollot.jpg",
      "grade": "Sophomore"
    },
    {
      "name": "Aryan Khattar",
      "desc": "Aryan is a sophomore student from Singapore who intends to major in Computer Science and Economics with a minor in Government in the college of Arts and Sciences. Outside of Alpha Fund, his other on-campus involvements include Cornell Financial Analysts and Cornell Alternative Investments. In his free time, Aryan enjoys golf, poker and hanging out with his friends. ",
      "pic": "aryan_khattar.jpg",
      "grade": "Sophomore"
    },
    {
      "name": "Graham Biben",
      "desc": "Graham Biben is a sophomore from New York City majoring in Applied Economics and Management. On campus, he is a right-handed pitcher on the Varsity Baseball Team, a Real Estate Sector Analyst for The Mutual Investment Club of Cornell, and a Course Assistant for AEM 2200 (Business Management and Organization). This past summer, he worked at The Carlyle Group as a Summer Analyst with their Insurance Solutions team. Graham enjoys listening to country music, playing fantasy football, and exploring new restaurants. He can be reached at gb532@cornell.edu.",
      "pic": "graham_biben.jpg",
      "grade": "Sophomore"
    },
    {
      "name": "Aiden Rourke",
      "desc": "Aiden is a sophomore from Westport, CT, majoring in Industrial and Labor Relations with minors in business and history. On campus he is involved with Cornell Financial Analysts and the Cornell Alexander Hamilton Society. This past summer, Aiden interned at a private equity fund based in Boston. Aiden is passionate about history, loves watching the UFC, and enjoys snowboarding. Aiden can be reached at ajr327@cornell.edu.",
      "grade": "Sophomore",
      "pic": "aiden_rourke.jpg"
    },
    {
      "name": "Vatsal Kalola",
      "desc": "Vatsal Kalola is a sophomore from Long Island majoring in Applied Economics and Management with a concentration in Finance and an intended minor in data science. On campus, he is also a member of Cornell Equity Research and Cornell Global Business Club. This past summer he worked in both private wealth management and venture capital. Vatsal enjoys sports and the outdoors. He can be reached at vk366@cornell.edu.",
      "grade": "Sophomore",
      "pic": "vatsal_kalola.jpg"
    },
    {
      "name": "RJ Druker",
      "desc": "RJ Druker is a sophomore from New York City studying Economics and pursuing minors in Business and Law & Society. Aside from Alpha Fund, he is a member of TAMID at Cornell’s Investment Fund program. This past summer, he studied at the London School of Economics and interned under the CFO of ViroCell Biologics in London. RJ enjoys running and cooking dishes from Thai and French cuisines. He can be reached at rjd295@cornell.edu.",
      "grade": "Sophomore",
      "pic": "rj_druker.jpg"
    },
    {
      "name": "Dev Shukla",
      "desc": "Dev Shukla is a sophomore from New Delhi, India majoring in Applied Economics and Management & Information Science. On campus, he is also part of the Cornell Investment Banking Club, South Asian Business Association and Cornell Rocketry Team. This past summer, he worked as a private equity intern at Convergent Finance and was staffed on PIPEs and buyout deals. He was a professional cricket player for 7+ years and now enjoys boxing, paintbal, poker and lifting.",
      "grade": "Sophomore",
      "pic": "dev_shukla.jpg"
    },
    {
      "name": "Raeyaan Bengali",
      "desc": "Raeyaan Bengali is a sophomore from Karachi, Pakistan, but he grew up in Dubai. He is majoring in economics and information science with a concentration in data science. On campus Raeyaan is also involved with Cornell Financial Analysts. This past summer he worked as a Private Equity analyst at Everstone Capital in Singapore. He can be reached at rrb245@cornell.edu",
      "grade": "Sophomore",
      "pic": "raeyaan_bengali.jpg"
    }
  ]
}