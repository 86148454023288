
import Person from '../components/Person';
import { alumni } from '../config';

const Alumni = () => (
  <div className="py-24 text-center">
    <h2 className="text-4xl font-bold mb-12">Placements</h2>
    <p className="mb-12 text-xl">
      Cornell Alpha Fund's rigorous education, tangible investment experience, and alumni network take our members to top investment banks, private equity firms, and hedge funds.
    </p>
    <div className="w-full flex justify-center">
      <img
        src="placements.png"
        alt="Description of the image"
        class="w-full md:w-9/12 h-auto object-cover px-12"
      />
    </div>

    <h2 className="text-4xl font-bold mb-12 mt-24">Alumni</h2>
    <p className="mb-12 text-xl">
      Below are some of the talented alumni who have passed through our organization.
    </p>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      {alumni.map((person) => (
        <Person key={person} name={person.name} role={person.company} image={"alpha_fund_alumni_pics/" + person.img} clickable={false} />
      ))}
    </div>
  </div>
);

export default Alumni