export default function About() {
  return (
    <div className="flex flex-col" id="about">
      <section class="">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img class="w-full" src="alphafund_team.png" alt="Alpha Fund Team" />
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Building skills for careers in finance</h2>
            <p class="mb-6 font-light text-gray-500 md:text-lg">
              For 17 years, Cornell Alpha Fund has instilled the core principles of value investing in its analysts, which they have gone on to employ in careers in equity and credit investing, private equity, investment banking, and investment research. Analysts learn and apply these fundamentals as part of dynamic, industry-agnostic teams by pitching public equity positions across multiple sectors.
            </p>
            <a href="/team" class="inline-flex items-center text-black bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-15 py-2.5 text-center">
              Meet our team
              <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
          </div>
        </div>
      </section>
      <section class="">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">New member education program</h2>
            <p class="mb-6 font-light text-gray-500 md:text-lg">
              Cornell Alpha Fund's new member education program is led by our President and Executive Vice President. Throughout this 10-week program, analysts hone three key topics: (1) investment banking essentials of accounting, valuation, M&A, and capital markets, (2) value investing strategies such as fundamental analysis, merger arbitrage, special situations investing, activist investing, and distressed investing, and (3) professional development skills enhanced through resume and networking email workshops, as well as coffee chats with current members and alumni.
            </p>
          </div>
          <img class="w-full" src="alphafund_nme.png" alt="Alpha Fund Education Series" />
        </div>
      </section>
      <section class="">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img class="w-full" src="investment_series.jpg" alt="Alpha Fund Investor Series" />
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Investor series</h2>
            <p class="mb-6 font-light text-gray-500 md:text-lg">
              Cornell Alpha Fund's investor series is led by our Co-Investment Managers. Throughout this 10-week program, analysts will focus on how to identify and exploit mispricings within the public market. This course will help analysts focus on the crux of Alpha Fund's mission: pitching successful investments to compound our portfolio. Our curriculum mainly focuses on value investing and long/short equity. Analysts will learn how to understand a company's business model, identify the key drivers of its stock price, and develop original variant views and theses.
            </p>
          </div>
        </div>
      </section>
      {/*<section class="">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img class="w-full" src="alphafund_investment_teams.png" alt="Alpha Fund Team" />
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Investment teams & industries</h2>
            <p class="mb-6 font-light text-gray-500 md:text-lg">
              For 17 years, members of Cornell Alpha Fund have focused on researching companies and preparing pitch...


              Founded in 2007, Cornell Alpha Fund Club is one of the longest-running student-led finance clubs on campus.
              Designed for the dual purpose of education and network expansion,
              Cornell Alpha Fund Club was founded on the principles of partnership, teamwork, and curiosity.
              Founded in 2007, Cornell Alpha Fund Club is one of the longest-running student-led finance clubs on campus.
              Designed for the dual purpose of education and network expansion,
              Cornell Alpha Fund Club was founded on the principles of partnership, teamwork, and curiosity.
            </p>
          </div>
        </div>
      </section>*/}
    </div>
  )
}